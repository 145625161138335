

























































import { Reports, User, Global } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import PropUtils from '@/modules/PropUtils';
import { i18n } from '@/main';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import MessageDialog from '@/components/MessageDialog.vue';
import Table from '@/components/Table.vue';
import Utils from '@/modules/Utils';

@Component({ components: {
    MessageDialog,
    Table
}})

export default class CommissionedReport extends Vue {
    @Global.State('lang') lang;
    @Reports.State('reportsList') devices;
    @User.State('project') project;

    reportData = [];
    periodSelected: string = '';
    periodFrom: string = '';
    periodTo: string = ''; 
    loadingReport = false;
    loading = false;
    noData = false;
    dates = [];
    showMessage = false;
    message = 'You must insert both from & to dates';
    headers = [{title: 'ID', field: 'id'}, {title: 'Date', field: 'date'}, {title: 'Installer', field: 'commissioner'}, {title: 'Location', field: 'location'}];

    options = null;
    
    mounted() {
        this.generatePageData();
    }

    @Watch('devices')
    generatePageData(){
        this.loading = true;

        const yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
        const lastWeek = moment().add(-7, 'day').format('YYYY-MM-DD');
        const lastMonth = moment().add(-1, 'month').format('YYYY-MM-DD');

        this.dates = [yesterday, lastWeek, lastMonth];
        
        this.loading = false;
    }

    get periodsList(){
        return [
            { text: i18n.t('Last 24 hours'), value: this.dates[0] },
            { text: i18n.t('Last week'), value: this.dates[1] },
            { text: i18n.t('Last month'), value: this.dates[2] },
            { text: i18n.t('custom'), value: 'custom' }
        ];
    }

    clearData() {
        this.reportData = [];
        this.noData = false;
        this.periodFrom = '';
        this.periodTo = '';
    }

    generateReport() {
        this.noData = false;
        
        this.reportData = [];
        
        const period = this.getPeriod();
        if (!period) {
            this.showMessage = true;
            return;
        }

        this.loadingReport = true;

        this.getCommissionedFixtures(period);
        this.reportData.length 
            ? this.reportData.sort((d1, d2) => moment(d2.date).unix() - moment(d1.date).unix())
            : this.noData = true;
        this.loadingReport = false;
    }

    getCommissionedFixtures(period){
        this.devices.forEach((device) => {
            const commissionProperty = device['meta.commission'];
            const success = commissionProperty.commissioned && Utils.hasTondoClass(device.class_name) && this.checkInPeriod(commissionProperty, period);
            if (success) this.reportData.push(this.parseData(device, commissionProperty));
        });
    } 

    checkInPeriod(commission, period){
        const commissionDate = Utils.convertCommissionDate(commission.date, 'YYYY-MM-DD');
        const result = commissionDate === '' ? false : moment(commissionDate).isBetween(period.from, period.to, undefined, '[]');
        return result;
    }

    parseData(device, commission){
        const coords = device['meta.location'];
        const rowData = {
            id: device.id,
            date: Utils.convertCommissionDate(commission.date, 'DD/MM/YYYY') || 'none',
            commissioner: commission.commissioner,
            location: this.getLocation(coords)
        };

        return rowData;
    }

    getLocation(coords){
        return coords.lat && coords.lng 
            ? `(${coords.lat} , ${coords.lng})`
            : 'none';
    }

    getPeriod() {
        let period;
        if (this.periodSelected === 'custom') {
            if (!this.periodFrom || !this.periodTo) return;
            period = {
                from: moment(this.periodFrom).format('YYYY-MM-DD'),
                to: moment(this.periodTo).format('YYYY-MM-DD')
            };
        }
        else {
            period = {
                from: this.periodSelected,
                to: moment().format('YYYY-MM-DD')
            };
        }
        return period;
    }
}
